import { useEffect } from 'react';
import { withRouter, Route, Switch, useLocation } from 'react-router-dom';

import routes from 'routes';

import 'assets/css/pe-icon-7.css';
import 'assets/scss/themes.scss';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {routes.map((route, idx) => (
        <Route path={route.path} component={route.component} key={idx} />
      ))}
    </Switch>
  );
};

export default withRouter(App);
