import { Component, Suspense, lazy } from 'react';

const Navbar = lazy(() => import('../components/Navbar/NavBar'));
const Section = lazy(() => import('../components/Section'));
const About = lazy(() => import('../components/About'));
const Services = lazy(() => import('../components/Services'));
const Clients = lazy(() => import('../components/Clients'));
const Contact = lazy(() => import('../components/Contact'));
const Footer = lazy(() => import('../components/Footer/Footer'));

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: 'home', navheading: 'Home' },
        { id: 2, idnm: 'about', navheading: 'About' },
        { id: 3, idnm: 'menu', navheading: 'Menu' },
        { id: 4, idnm: 'review', navheading: 'Review' },
        { id: 5, idnm: 'contact', navheading: 'Contact' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: 'navbar-light',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky navbar-light', imglight: false });
    } else {
      this.setState({ navClass: 'navbar-light', imglight: true });
    }
  };

  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Suspense fallback={this.PreLoader()}>
        <Navbar
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />
        <Section />
        <About />
        <Services />
        <Clients />
        <Contact />
        <Footer />
      </Suspense>
    );
  }
}
export default Home;
